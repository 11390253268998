.page-header{
  
    background-color: rgba(0, 0, 0, 0.363);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    
}
p,h1,h2,h3,h4,h5{
    color: #555;
}
body,html{
    padding: 0;
    margin: 0;
    width: 100vw;
}
.page-logo h4{
    font-size: 16px;
    color: yellow;
}
.page-links{
    float: right;
    margin-right: 10px;
}

.page-links li{
    display: inline;
    text-transform: uppercase;
    color:#ffffff;
    margin-left: 15px;
}
.page-links ul{
    list-style-type: none;
    
}
.page-logo{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-logo img{
    margin: 5px;
}

html,p {
    font-family:'Open Sans', sans-serif, Tahoma, Verdana !important;
}
.create-event-button{
    margin-left: 10px;
    padding: 8px;
    border-radius: 10px;
}
.create-event-button:focus{
    outline: 0;
    box-shadow: 0 0 10px #F8BC00; 
}
.image-container{
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.image-container p{
    text-align: center;
    padding:0 10vw 0 10vw;
    color: #F8BC00;
    font-size: 24px;
    /* font-weight: bolder !important; */
    line-height: 35px;
   /* text-transform: uppercase; */
}
.main-container{
    overflow-x: hidden;
}
.get-app{
    display: inline;
    
}
.organizer-h4{
    text-align: center;
    margin: 0;
   /* border-bottom: 1px solid gray;*/
    
}

.row{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
}
.col-6{
    flex: 1;
   padding: 10px;
}

.organizer-article{
    line-height: 40px;
}
.about-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.event-button{
    margin-top: 20px;
}
.about-section{
    background-color: white;
    padding-top: 20px;
    margin-top: 0;
   /* height: 100vh;*/
}
.venu-section{
    background-color: #ffffff;
    margin: 10px;
}
.venu-section div{
   /* border:1px solid orange;*/
    padding: 10px;
    min-height: 60vh;
}
.venu-section ul{
 list-style-type: none;
 margin: 0;
 padding: 0;
}
.venu-section ul li{
    /* display: inline; */
    margin-right: 15px;
    font-size: 20px;
    line-height: 7vh;
}
.map{
    width: 100vw;
    height: 60vh;
}

.attend-procedure li{
font-size: 24px;
line-height: 65px;
}

.center-phone{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ticket-card{
    /* background: linear-gradient(to bottom, rgba(3, 15, 41, 0.599), rgba(3, 15, 41, 0.563), rgba(3, 15, 41, 0.153)); */
    min-height: 55vh;
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);;

}
.buy-ticket{
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
   /* background-color: #F8BC00;*/
    /* min-width: 200vw; */
    /* width: 100vw; */
   /* overflow-x: scroll; */
}

.ticket-card{
 padding: 14px;
}

.ticket-head1{
    flex: 1;
  /* background-image:linear-gradient(to right, rgba(3, 15, 41, 0.599), rgba(3, 15, 41, 0.563), rgba(3, 15, 41, 0.153)), url('../assets/bg/background_1920-15.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.data-ul{
    color:#000000;
    line-height:30px;
    list-style-type: none;
    padding:1px;
    /* padding-left: 10px; */
}
.color-icon{
    color: #F8BC00;
    margin-right: 8px;
}
.ticket-body{
    display: flex;
    flex: 1;
    flex-direction: row;
}

.left-ticket-content, .right-ticket-content{
    flex: 1;
}
.right-ticket-content{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.left-ticket-content{

 color: #ffffff;
}
 .left-ticket-content h4, .left-ticket-content h3{
    color: #000000;
    
}

.speaker-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);;

}
.links-event{
    color: #e4e3e3f6;
    text-decoration: none;
    transition: color 0.5s text-decoration 0.5s;
   
}
.links-event:hover{
    color: #fffbfb;
    text-decoration: underline;
}

.img-flex{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
  
}

.attend-event{
    background-color: #030F29;
    color: #ffffff;
}

.ticket-footer{
/* background-color: #F8BC00; */
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
height: 100px;
}
.speaker-list{
    margin: 10px;
}
.center-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}